.wrap-corridor-offset-result {
  .wrap-header-corridor-offset {
    flex: 0 0 200px;
  }
  .wrap-table-corridor-offset-optimization {
    overflow: auto;
    table,
    th,
    td {
      border: 1px solid var(--border-color);
      padding: 8px;
      b {
        margin-bottom: 16px;
      }
      p {
        margin-bottom: 8px;
      }
    }
    table {
      width: 100%;
    }
  }
  .chart-title {
    margin-left: 64px;
    font-size: 20px;
    font-weight: 600;
  }
  .pie-chart-detail {
    height: 160px;

    // overflow: overlay !important;
    .arc:hover {
      path {
        transform: scale(1.1);
      }
    }
    // .pie-item-value {
    //   display: none;
    // }
    .pie-value {
      color: var(--text);
      font-size: 22px;
      font-weight: 500;
    }
    .pie-text {
      color: var(--text);
      font-size: 20px;
      opacity: 0.8;
    }
  }
  .bar-chart-detail {
    height: calc(100% - 64px);
    .y-axis {
      .tick {
        stroke-dasharray: 2, 2;
      }
    }
    .x-axis-botttom {
      .tick {
        stroke-dasharray: 2, 2;
      }
    }
  }
}
