code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ant-calendar-time-picker-btn {
  text-transform: capitalize !important;
}
.ant-calendar-ok-btn {
  display: none !important;
}

.ant-tag-checkable {
  border: 1px solid var(--border-color) !important;
}
.App {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
}

.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}
.relative {
  position: relative;
}
td,
th,
p,
.ant-tabs-tab,
.ant-descriptions-item-colon,
.ant-descriptions-item-content {
  color: var(--text) !important;
}
.ant-tooltip-inner {
  p {
    color: white !important;
  }
  .ant-tooltip-pre-wrap {
    white-space: pre-wrap;
  }
}
.ant-scroll-number-only > p {
  color: white !important;
}
.ant-tabs-tab-active {
  border-bottom: transparent !important;
}
.ant-descriptions-item-colon {
  font-weight: bold !important;
}
.App-logo {
  height: 40vmin;
}
.ant-page-header-heading-title {
  line-height: 40px;
  font-size: 24px;
}
.ant-page-header-heading-title,
.ant-pagination-total-text {
  color: var(--text);
}
.ant-radio-disabled + span {
  color: var(--gray-text) !important;
}
.ant-pagination-options-quick-jumper {
  color: var(--text) !important;
}
.ant-pagination-next a,
.ant-pagination-prev a {
  background: var(--content-background) !important;
  color: var(--text) !important;
}
.ant-pagination-item a {
  color: var(--text) !important;
}
.ant-pagination-item,
.ant-pagination-item-active {
  background: var(--content-background);
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.h-screen {
  height: 100vh;
}
.App-link {
  color: #09d3ac;
}

.logo {
  height: 40px;
  margin: 12px;
}
.logo-img {
  fill: var(--logo-color);
}

.overlay {
  fill: none;
  pointer-events: all;
}
div {
  color: var(--text);
}
.ant-badge-status-text {
  color: var(--text);
}
.dot {
  fill: #ffab00;
  stroke: #fff;
}
.ant-btn-background-ghost.ant-btn-link {
  color: var(--text);
}
.flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.inline-flex {
  display: inline-flex !important;
}
.leaflet-pulsing-icon {
  border-radius: 100%;
}

.leaflet-pulsing-icon:after {
  content: "";
  border-radius: 100%;
  height: 300%;
  width: 300%;
  position: absolute;
  margin: -100% 0 0 -100%;
  opacity: 0;
}
.ant-tabs-tab-active {
  border-bottom: var(--background) !important;
}
h1 {
  color: var(--text);
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text);
}
@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
  50% {
    opacity: 1;
    -ms-filter: none;
    filter: none;
  }
  100% {
    transform: scale(1.2, 1.2);
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
  }
}
.ant-popover-title {
  color: var(--text);
}
h3.ant-typography,
.ant-typography h3 {
  color: var(--text);
}
.justify-start {
  justify-content: flex-start;
}
.bottom-0 {
  bottom: 0px;
}
.w-fit {
  width: fit-content;
}
.w-half {
  width: 50%;
}
.w-240 {
  width: 240px;
}
.w-320 {
  width: 320px;
}
.w-max {
  width: max-content;
}
.w-128 {
  width: 128px;
}
.w-160 {
  width: 160px;
}
.w-240 {
  width: 240px;
}
.w-16 {
  width: 16px;
}
.w-120 {
  width: 120px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-32 {
  margin-top: 32px;
}
.mt-64 {
  margin-top: 64px;
}
.mb-0 {
  margin-bottom: 0px;
}

.mr-8 {
  margin-right: 8px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-8 {
  margin-bottom: 8px;
}
.mr-16 {
  margin-right: 16px;
}
.mr-20 {
  margin-right: 20px;
}
.mr-32 {
  margin-right: 32px;
}
.ml-4 {
  margin-left: 4px;
}
.ml-8 {
  margin-left: 8px;
}
.ml-16 {
  margin-left: 8px;
}
.ml-32 {
  margin-left: 32px;
}
.mb-16 {
  margin-bottom: 16px;
}
.p-16 {
  padding: 16px;
}
.pl-0 {
  padding-left: 0;
}
.pr-0 {
  padding-right: 0;
}
.p-4 {
  padding: 4px;
}
.p-icon {
  padding: 2px 10px 0 0;
}
.m-0 {
  margin: 0;
}
.mx-8 {
  margin-left: 8px;
  margin-right: 8px;
}
.my-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}
.my-8 {
  margin-top: 8px;
  margin-bottom: 8px;
}
.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}
.px-8 {
  padding-left: 8px;
  padding-right: 8px;
}
.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}
.py-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}
.pt-16 {
  padding-top: 16px;
}
.pt-8 {
  padding-top: 8px;
}
.pb-16 {
  padding-bottom: 16px;
}
.pb-8 {
  padding-bottom: 8px;
}
.gap-1 {
  gap: 4px;
}
.gap-2 {
  gap: 8px;
}
.gap-3 {
  gap: 12px;
}
.gap-4 {
  gap: 16px;
}
.gap-5 {
  gap: 20px;
}
.w-full {
  width: 100%;
}
.text-bold {
  font-weight: bold;
}
.text-lighter {
  font-weight: lighter;
}
.text-center {
  text-align: center;
}
.ant-descriptions-title {
  color: var(--text) !important;
}
.bg-wrap-background {
  background-color: var(--wrap-background);
}
.bg-background {
  background-color: var(--background);
}
.ant-radio-button-wrapper {
  color: var(--text);
  background-color: var(--background);
}
.items-center {
  align-items: center;
  align-self: center;
}
.items-end {
  align-items: flex-end;
}
.flex-col {
  flex-direction: column;
}
.overflow-auto {
  overflow: auto;
}
.overflow-x-auto {
  overflow-x: auto;
}
.flex-1-1-auto {
  flex: 1 1 auto;
}
.flex-0-0-auto {
  flex: 0 0 auto;
}
.line-height-0 {
  line-height: normal;
}
.items-start {
  align-items: start;
}
.h-full {
  height: 100%;
}
.h-16 {
  height: 16px;
}
.justify-end {
  justify-content: flex-end;
}
.justify-space-beetwen {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-space-around {
  justify-content: space-around;
}
.clickable {
  cursor: pointer;
}
svg.icon {
  width: 14px;
  height: 14px;
}
.focus circle {
  fill: none;
  stroke: steelblue;
}
.large-icon {
  font-size: 20px;
}
.wrap-mini-loading-outside {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10000;
  width: 100%;
  height: 6px;
}

.deep-1 {
  box-shadow: -10px 0px 18px 1px;
}
.centralize {
  padding: 25%;
}
.fullscreen {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  z-index: 1000;
}
.ant-popover-inner {
  background: var(--background) !important;
}

.scroll-y {
  overflow-y: auto !important;
  scroll-behavior: smooth;
  &:hover {
    overflow-y: auto !important;
  }
  &::-webkit-scrollbar {
    transition: 2s;
    height: 8px;
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 15px;
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
}
.styled-scroll {
  overflow: auto !important;
  scroll-behavior: smooth;
  &:hover {
    overflow: auto !important;
  }
}
* {
  scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
  padding: 2px 0;
}

*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

*::-webkit-scrollbar-thumb {
  scrollbar-width: thin;
  border-radius: 10px;
  background-color: gray;
  border: 1px solid gray;
}
.color-danger {
  color: red;
}
.profile-drawer {
  .ant-drawer-wrapper-body {
    height: 100%;
    overflow: hidden;
    background: var(--background);
    .ant-drawer-body {
      height: calc(100% - 55px);
    }
    .ant-drawer-content-wrapper {
      width: 320px;
      background: var(--background);
    }
    .drawer-footer {
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      padding: 0px 10px;
      .nav-text {
        margin-left: 10px;
      }
    }
    .ant-drawer-header {
      background: var(--background);
      border-bottom: none;
      .ant-drawer-title {
        color: var(--text);
      }
    }
    .ant-drawer-close {
      color: var(--text);
    }
    .drawer-body-inside {
      height: calc(100% - 40px);
      overflow: overlay;
      left: 0;
      width: 100%;
    }
  }
}
.ant-menu-item a {
  color: var(--text);
}
.ant-menu-submenu {
  color: var(--text);
}
.ant-layout-sider-zero-width-trigger {
  top: 0px !important;
  border-radius: 0 !important;
  height: 64px !important;
  line-height: 64px !important;
}
.ant-drawer-content,
.ant-layout-sider {
  background: var(--content-background) !important;
}

.ant-layout-sider-trigger {
  background: transparent !important;
}

.ant-layout {
  height: 100%;
  background: var(--wrap-background) !important;
}

.ant-menu-item {
  text-align: left;
}

.spm-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.white-space-no-wrap {
  white-space: nowrap;
}

.ant-layout-content {
  overflow: overlay;
  color: var(--text) !important;
  background: var(--content-background);
  border-radius: 4px;
}
.ant-typography-disabled {
  color: var(--gray-text) !important;
}
.ant-typography {
  color: var(--text);
}
.ant-radio-wrapper {
  color: var(--gray-text) !important;
}
// .ant-table-wrapper {
// .ant-pagination {
//     float: left !important;
// }
// }
.ant-menu-horizontal > .ant-menu-submenu-selected {
  background-color: #c3d9d5;
}

.ant-menu-horizontal > .ant-menu-item-selected {
  background-color: #c3d9d5;
}
.ant-menu-item-selected {
  background-color: var(--highlight-color) !important;
}
.ant-drawer-content-wrapper {
  width: 50%;
  min-width: 480px;
  max-width: 640px;
}

// .ant-drawer-body {
//     padding: 0px !important;
//     height: 100%;
//     .wrap-form-layout {
//         height: 100%;
//     }
// }

.ant-form {
  height: 100%;
}

.ant-select {
  width: 100%;
}

.login-form-button {
  width: 100%;
}

.login-form-forgot {
  // color: #1890ff !important;
  float: right;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
.ant-pagination-item-ellipsis {
  color: var(--text) !important;
}
.ant-time-picker-icon > i {
  color: var(--gray-text) !important;
}
.ant-drawer-close,
.wrap-form-layout .header,
.ant-form-item label,
.ant-select-selection,
.ant-select-arrow,
.ant-input-number,
.ant-time-picker-input,
.ant-input {
  background: transparent !important;
  color: var(--gray-text) !important;
}
.ant-checkbox-wrapper > span {
  color: var(--text);
}
.ant-badge-status-text {
  color: var(--text) !important;
}
.border-square {
  * {
    border-radius: 2px;
  }
  span {
    width: 16px;
    height: 16px;
  }
}
.border-circle {
  * {
    border-radius: 50%;
  }
  span {
    width: 16px;
    height: 16px;
  }
}
.bordered {
  border: 1px solid var(--border-color);
}

.bordered-top {
  border-top: 1px solid var(--border-color);
}

.font-bold {
  font-weight: bold;
}
.ant-checkbox,
.ant-checkbox-wrapper-disabled > span {
  background: var(--background);
  color: var(--gray-text) !important;
}
.ant-input-password,
.ant-input {
  input,
  .ant-select-selection {
    background: transparent !important;
    color: var(--text);
  }

  .anticon,
  .anticon-down {
    color: var(--text) !important;
  }
}
.ant-input-affix-wrapper .ant-input-prefix {
  color: var(--text) !important;
}

.ant-input-suffix {
  .anticon {
    color: var(--text);
  }
}

.ant-modal-content {
  .ant-modal-close {
    .anticon {
      color: var(--text) !important;
    }
  }

  .ant-modal-header {
    // border: none !important;
    border-radius: 0;
    border-bottom: solid 1px var(--border-color);
    .ant-modal-title {
      color: var(--text) !important;
    }
  }

  .ant-modal-header,
  .ant-modal-body {
    background: var(--background) !important;
  }
  .ant-modal-footer {
    background: var(--background) !important;
  }
}

.spm-modal .ant-modal-confirm-btns {
  display: none;
}

.ant-modal-content {
  .ant-modal-close {
    .anticon {
      color: var(--text) !important;
    }
  }
  .ant-modal-header {
    border-radius: 0;
    .ant-modal-title {
      color: var(--text) !important;
    }
  }
  .ant-modal-header,
  .ant-modal-body {
    background: var(--background) !important;
  }
}

.ant-drawer-header {
  background: var(--content-background) !important;
  border-bottom: solid 1px var(--border-color);
  .ant-drawer-title {
    color: var(--text);
  }
}

.ant-form-item-children {
  color: var(--text);
}

.spm-modal .ant-modal-confirm-body {
  .anticon-info-circle {
    display: none;
  }
  .ant-modal-close {
    display: block;
  }
}

.ant-modal-confirm {
  .ant-modal-body {
    padding: 16px !important;
  }
  .ant-modal-close {
    display: block !important;
  }
  .ant-modal-confirm-content {
    margin-left: 0px !important;
    color: var(--text) !important;
  }
  .ant-modal-confirm-title {
    color: var(--text) !important;
  }
}
.ant-table-placeholder {
  border-radius: 0 !important;
  background: var(--content-background) !important;
  color: var(--text) !important;
}
.font-32 {
  font-size: 32px;
}
.font-24 {
  font-size: 24px;
}
.font-40 {
  font-size: 40px;
}
.font-12 {
  font-size: 12px;
}
.font-14 {
  font-size: 14px;
}
.font-16 {
  font-size: 16px;
}
.text-print {
  * {
    color: #606060 !important;
  }
  .table-bordered {
    td {
      border: 1px solid #ccc !important;
    }
    th {
      border: 1px solid #ccc !important;
    }
  }
}

.spm-table .ant-table-tbody > .odd-row {
  background-color: var(--odd-color) !important;
}
.spm-table .ant-table-tbody > .even-row {
  background: var(--background) !important;
}
.spm-table .ant-table-tbody > tr > td {
  color: var(--text) !important;
  border-bottom: solid 1px var(--border-color) !important;
  padding: 9px 16px;
  height: 49px;
}
.spm-table .ant-table-thead > tr > th {
  background: var(--odd-color) !important;
  color: var(--text) !important;
  border-bottom: solid 1px var(--border-color) !important;
}
.ant-empty-description,
.ant-calendar-picker-icon {
  color: var(--text) !important;
}

.spm-table .ant-table-footer {
  background: var(--content-background) !important;
  color: var(--text) !important;
  border-top: none !important;
  &::before {
    display: none;
  }
}
.spm-table .ant-table-tbody .ant-table-row:hover td {
  background: var(--wrap-background) !important;
}
.spm-table .ant-table-tbody > tr.ant-table-row-selected td {
  background: var(--highlight) !important;
}
.spm-error-comp {
  margin: 30px;
  white-space: pre-wrap;
}

.spm-detail-drawer {
  .ant-form-item-label {
    line-height: 20px;
  }
  .ant-form-item {
    margin-bottom: 14px;
  }
}

.spm-right-site-bar {
  .ant-drawer-wrapper-body {
    display: flex;
    flex-direction: column;
  }
  .ant-drawer-body {
    flex: 1;
    padding: 0 12px 24px 12px;
  }
}

.collapsable,
.collapsable-content {
  overflow: hidden;
  transition: all 0.3s;
}

.collapsable .expander::after {
  content: "";
  width: 1rem;
  height: 1rem;
  float: right;
  background: url("../../svgs/angle-up-solid.svg") no-repeat;
  transition: transform 0.3s;
}

.collapsable:not(.collapsed) .collapsable-content {
  transition: height, 0.3s linear;
}

.collapsable.collapsed .collapsable-content {
  height: 0;
  transition: height, 0.3s linear;
}

.collapsable.collapsed .expander::after {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.flex-grow {
  flex: 1 0 0;
}

.cell-full-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
